/* eslint-disable no-undef */
/* eslint-disable no-inner-declarations */
/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import { debugLog } from './debug';

const UserInfo = () => {
    const [userDisplayName, setUserDisplayName] = useState(undefined);
    const [userEmailAddress, setUserEmailAddress] = useState(undefined);
    const [senderName, setsenderName] = useState(undefined);
    const [senderEmail, setsenderEmail] = useState(undefined);
    const [userEmail, setuserEmail] = useState(undefined);
    const [userName, setuserName] = useState(undefined);
    const [ccEmails, setccEmails] = useState(undefined);
    const [toEmails, settoEmails] = useState(undefined);
    const [mailBody, setmailBoby] = useState(undefined);
    const [attachments, setattachments] = useState(undefined);
    const [attachmentNames, setattachmentNames] = useState(undefined);
    const [DateTime, setdateTime] = useState(undefined);
    const [message_id, setMessage_id] = useState(undefined);
    const [thread_id, setthread_id] = useState(undefined);
    const [title, settitle] = useState(undefined);

    useEffect(() => {
        const item = Office.context.mailbox.item;
        const displayName = Office.context.mailbox.userProfile.displayName;
        const emailAddress = Office.context.mailbox.userProfile.emailAddress;
        setUserDisplayName(displayName);
        setUserEmailAddress(emailAddress);

        // Message id
        let itemId = item.itemId;
        const encodeitemid = encodeURIComponent(itemId);
        setMessage_id(encodeitemid);

        // sender Name
        const senderName = item.sender.displayName;
        setsenderName(senderName);

        // sender email
        const senderemailAddress = item.sender.emailAddress;
        setsenderEmail(senderemailAddress);

        // user email
        const usermail = Office.context.mailbox.userProfile.emailAddress;
        setuserEmail(usermail);

        // user name
        const username = Office.context.mailbox.userProfile.displayName;
        setuserName(username);

        // All cc emails 
        const msgCc = item.cc;
        // Define arrays to store and emails 
        let ccEmails = [];
        for (let i = 0; i < msgCc.length; i++) {
            ccEmails.push(msgCc[i].emailAddress);
        }
        setccEmails(ccEmails);

        // All to emails
        const toRecipients = item.to;
        // Define an array to store email addresses
        const toEmails = [];

        toRecipients.forEach(recipient => {
            toEmails.push(recipient.emailAddress);
        });
        settoEmails(toEmails);

        item.body.getAsync(Office.CoercionType.Html, function (result) {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
                let emailBody = result.value;

                // Process the HTML to handle line breaks, &nbsp;, and remove trailing spaces
                const formattedBody = emailBody
                    .replace(/&nbsp;/g, ' ')                // Replace &nbsp; with a regular space
                    .replace(/<br\s*\/?>/g, '')             // Convert <br> tags to single line breaks
                    .replace(/<\/p>|<\/div>/g, '\n')        // Convert closing </p> and </div> to double line breaks
                    .replace(/<[^>]+>/g, '')                // Remove other HTML tags
                    .replace(/\s+$/, '');                   // Remove trailing spaces after the last word

                // Set the final plain text result
                setmailBoby(formattedBody);
            } else {
                debugLog("UserInfo.ts, Line No: 86, Failed to get the mail body:", result.error.message);
            }
        });

        // Get all attachment names
        const attachments = item.attachments;
        const attachmentsName = [];

        // Store all attachment details (base64, contentType, name) in an array
        const allAttachments = [];

        // Push all attachment names into an array
        attachments.forEach(function (attachment) {
            attachmentsName.push(attachment.name);
        });

        // Now attachmentNames contains all attachment file names
        setattachmentNames(attachmentsName);

        // Check if there are any attachments
        if (attachments.length > 0) {
            // Keep track of how many attachments are processed
            let processedCount = 0;

            // Loop through all attachments to get their content
            attachments.forEach((attachment, index) => {
                item.getAttachmentContentAsync(attachment.id, (result) => {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        const attachmentBase64 = result.value.content;
                        const data = {
                            base64: attachmentBase64,
                            contentType: attachment.contentType,
                            name: attachment.name
                        };

                        // Add the attachment data to the allAttachments array
                        allAttachments.push(data);

                        // Increment the processed count and check if all attachments are processed
                        processedCount++;
                        if (processedCount === attachments.length) {
                            // All attachments have been processed, update the state
                            setattachments(allAttachments);
                        }
                    } else {
                        debugLog(`UserInfo.jsx, Line No: 131, attachments for attachment ${index}`, result.error);
                    }
                });
            });
        } else {
            debugLog("UserInfo.jsx, Line No: 135,", "no attachment found");
        }

        // get Creation date and time 
        const dateTime = item.dateTimeCreated;
        const creationDateTime = new Date(dateTime);
        const formattedCreationDateTime = creationDateTime.toISOString();
        setdateTime(formattedCreationDateTime);

        const threadid = item.conversationId;
        const encodethreadid = encodeURIComponent(threadid)
        setthread_id(encodethreadid);

        const subject = item.subject;
        settitle(subject);
    }, []);

    return { userDisplayName, userEmailAddress, senderName, senderEmail, userEmail, userName, ccEmails, toEmails, mailBody, attachments, attachmentNames, DateTime, message_id, thread_id, title };
}

export default UserInfo;