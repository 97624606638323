/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Stack, Typography, Box, Divider } from '@mui/material';
import UIText from "../../utils/TextResources";
import useLoader from "../../utils/UseLoader";
import Loader from "../../Loader/Loader";
import SupplierFile from "../Supplier/SuppliersFiles";
import Footer from "../Footer/Footer";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SuppliersItems from "../Supplier/SuppliersItems";

const SuggestionData = ({ Getmessages, selectedObjectType }) => {
    const isLoading = useLoader();
    const [loadingSupplierInfo, setLoadingSupplierInfo] = useState(false);
    const [showSuggestion, setShowSuggestion] = useState(true);
    const [RFQUrl, setRFQUrl] = useState("https://axya.co/en/");
    const [visited, setVisited] = useState(false);

    // Handle the click event to update the visited state
    const handleLinkColorAfterClick = () => {
        setVisited(true);
    };

    let messageData = Getmessages;
    const toggleSuggestion = () => {
        setShowSuggestion(prevState => !prevState);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoadingSupplierInfo(true);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>
            {Getmessages && (
                <Stack padding="8px">
                    <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        {/* show Request/Order based on selectedObjectType */}
                        {selectedObjectType && (
                            <Typography sx={{ fontWeight: "bold", flexGrow: 1 }}>
                                {selectedObjectType === "RFQ"
                                    ? UIText.suggestions.requests
                                    : selectedObjectType === "PO"
                                        ? UIText.suggestions.order
                                        : UIText.suggestions.requests
                                }
                            </Typography>
                        )}

                        {/* Show appropriate icon on the right side */}
                        {showSuggestion ? (
                            <KeyboardArrowUpIcon sx={{ cursor: "pointer" }} onClick={toggleSuggestion} />
                        ) : (
                            <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} onClick={toggleSuggestion} />
                        )}
                    </Stack>

                    {showSuggestion && (
                        <>
                            <Typography
                                component="a"
                                href={messageData.type === "RFQ" ? RFQUrl : "#"} // Add the URL only if the type is "RFQ"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={handleLinkColorAfterClick}
                                sx={{
                                    paddingTop: "8px",
                                    color: visited ? "#624991" : "#007DCE",  // Change color when visited
                                    textDecoration: "underline",
                                    "&:hover": {
                                        textDecoration: "none",
                                        cursor: "pointer"
                                    }
                                }}
                            >
                                {messageData.name}
                            </Typography>
                            <button
                                style={{
                                    backgroundColor: "#E9F3FF",
                                    border: "none",
                                    maxWidth: "24%",
                                    borderRadius: "25px",
                                    color: "#249EE4",
                                    padding: "8px 8px",
                                    textAlign: "center",
                                    textDecoration: "none",
                                    display: "inline-block",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    margin: "4px 0 0 -2px"
                                }}
                            >
                                {messageData.status}
                            </button>

                            <Divider sx={{ maxWidth: "98%", paddingTop: "22px" }} />

                            <Typography sx={{ paddingTop: "6%", color: "#959595" }}>{UIText.supplier.supplier}</Typography>
                            <Stack sx={{ display: "flex", flexDirection: "row", marginTop: "4px" }}>
                                <img src={messageData.supplier.picture} alt="S" style={{ borderRadius: "6px" }} height={40} width={40} />
                                <Typography sx={{ padding: "10px", fontSize: "14px" }}>{messageData.supplier.name}</Typography>
                            </Stack>
                            <Divider sx={{ maxWidth: "98%", paddingTop: "22px" }} />

                            {isLoading && (
                                <Box sx={{ display: 'flex', flexDirection: "row", marginTop: "8px" }}>
                                    <Loader loader={{ size: "26px" }} />
                                    <Typography sx={{ padding: "2px", paddingLeft: "8px", color: "#959595" }}>{UIText.supplier.loadingInfo}</Typography>
                                </Box>
                            )}

                            {loadingSupplierInfo && (
                                <>
                                    <SupplierFile messages={messageData} />
                                    <SuppliersItems messages={messageData} />
                                </>
                            )}
                        </>
                    )}

                    {loadingSupplierInfo && (<Footer messages={messageData} />)}
                </Stack>
            )}
        </>
    );
};

export default SuggestionData;
