/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
const UIText = {
    login: {
        header: "Sync email to RFQs and Orders in Axya with a few clicks.",
        connected: "Log in to start using AxyaSync",
        point1: "1. Navigate to ",
        axya: "Axya",
        point2: "2. Access the profile settings.",
        point3: "3. Select the email integration tab.",
        point4: "4. Generate a code.",
        point5: "5. Paste the code here.",
        point6: "Still don't have an account?",
        join: "Join Axya",
        authbutton: "Authenticate",
    },
    header: {
        thread: "Thread",
        part: "Late part",
        trackOnAxya: "Track on Axya",
        trackOnQuotes: "Track on Quotes",
        avtarName: "Yenna Yang"
    },
    suggestions: {
        searching: "Searching for suggestions...",
        not_available: "Suggestions not available",
        suggestion: "Suggestion:",
        requests: "Requests",
        order: "Orders",
        suggestionName: "AO10000011",
    },
    supplier: {
        supplier: "Supplier",
        loadingInfo: "Loading Information",
    },
    files: {
        filesList: "Attached files",
        notFiles: "Files not available"
    },
    items: {
        itemsList: "Items list",
        notItems: "Items not available"
    },
    footer: {
        active: "Activity history",
        noHistory: "Activity history not available"
    },
    logout: {
        connected: "Your inbox is connected",
        logoutText: "Log out"
    }
};

export default UIText;