/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { constants } from "../utils/Constants";
import { debugLog } from "../utils/debug";

export const SuggestionList = (accessToken, senderName, senderEmail, ccEmails, toEmails, mailBody, DateTime, message_id, thread_id, title, attachmentNames, apiCalled, callback) => {
    if (accessToken && senderName != undefined && senderEmail != undefined && ccEmails != undefined && toEmails != undefined && mailBody != undefined && DateTime != undefined && message_id != undefined && thread_id != undefined && title != undefined && apiCalled) {
        var settings = {
            "url": `${constants.BASE_URL}/api/v1/email/suggest/`,
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/json",
                "Authorization": `JWT ${accessToken}`
            },
            "data": JSON.stringify({
                "title": title,
                "thread_id": thread_id,
                "messages": [
                    {
                        "message_id": message_id,
                        "from_email": senderEmail,
                        "from_name": senderName,
                        "to": toEmails,
                        "cc": ccEmails,
                        "body": mailBody,
                        "attachments": attachmentNames,
                        "date": DateTime,
                    }
                ]
            }),
        };

        $.ajax(settings)
            .done(function (response) {
                callback(null, response);
            })
            .fail(function (error) {
                callback(error, null);
            });
    } else {
        debugLog("SuggestionList.jsx, Line No: 42,", "SuggestionList API not call due undefined his body parameters");
    }
}