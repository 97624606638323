/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import { constants } from '../utils/Constants';
import { debugLog } from '../utils/debug';

export const UnSyncMessage = (accessToken, thread_id, callback) => {
    if (accessToken && thread_id != undefined) {
        var settings = {
            "url": `${constants.BASE_URL}/api/v1/email/unsync/${thread_id}/`,
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/json",
                "Authorization": `JWT ${accessToken}`
            },
        };

        $.ajax(settings)
            .done(function (response) {
                callback(null, response);
            })
            .fail(function (error) {
                callback(error, null);
            });
    } else {
        debugLog("UnSyncMessage.jsx, Line No: 29,", "UnSyncMessage API not call due to undefined thread_id parameter");
    }
}
