/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { Button, TextField, Typography, Link } from "@mui/material";
import KeyIcon from '@mui/icons-material/Key';
import Loader from "../../Loader/Loader";
import useLoader from "../../utils/UseLoader";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from "react-router-dom";
import validateCredentials from "../../Auth/Config";
import UIText from "../../utils/TextResources";
import { Token } from "../../utils/accessToken";
import { debugLog } from "../../utils/debug";

const Login = () => {
    const isLoading = useLoader();
    const navigate = useNavigate();
    const accessToken = Token();
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [loading, setloading] = useState(isLoading);
    const [error, setError] = useState(null); // State to store error message

    useEffect(() => {
        setloading(true);

        const timer = setTimeout(() => {
            setloading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Validation
        if (!code) {
            setCodeError(true);
        } else {
            setCodeError(false);
        }
        if (code) {
            setloading(true);
            validateCredentials(code)
                .then(() => {
                    // If validation is successful, navigate to "/header"
                    setloading(false);
                    setError(null);
                    navigate("/header");
                })
                .catch((error) => {
                    // If validation fails, log the error
                    debugLog("Login.js, Line No: 56, An error occurred while validating credentials:", error.message);
                    setloading(false);
                    setError(error.message);
                });
        }
    };
    const isCodeValid = code.length >= 8;

    useEffect(() => {
        if (accessToken) {
            navigate("/header");
        } else {
            navigate("/");
        }
    }, []);

    return (
        <>
            {loading && (
                <Loader
                    loaderOverlay={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                    loader={{ size: "40px" }}
                />
            )}

            <Divider sx={{ maxWidth: "98%", }} />
            <Typography
                sx={{
                    fontWeigt: "bold",
                    textAlign: "center",
                    marginLeft: "41px",
                    marginRight: "41px",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
            >
                {UIText.login.header}
            </Typography>
            <Divider sx={{ maxWidth: "98%", }} />

            <Stack padding="22px" spacing={2} component="form" onSubmit={handleSubmit}>
                <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FiberManualRecordIcon style={{ color: "#D9D9D9" }} />
                    <Typography variant="body1" sx={{ marginLeft: "10px", fontWeight: "bold" }}>{UIText.login.connected}</Typography>
                </Stack>
                <Stack padding="4px" paddingLeft="12px">
                    <Typography>
                        {UIText.login.point1}
                        <Link href="https://axya.co/en/" target="_blank">{UIText.login.axya}</Link>.
                    </Typography>
                    <Typography>
                        {UIText.login.point2}
                    </Typography>
                    <Typography>
                        {UIText.login.point3}
                    </Typography>
                    <Typography>
                        {UIText.login.point4}
                    </Typography>
                    <Typography>
                        {UIText.login.point5}
                    </Typography>
                </Stack>
                <TextField
                    label="Code"
                    id="code"
                    size="small"
                    className="textField"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    error={codeError}
                    helperText={codeError ? "Code is required" : ""}
                />
                <Button
                    startIcon={<KeyIcon />}
                    type="submit"
                    variant="contained"
                    className="textField"
                    disabled={!isCodeValid}
                    sx={{
                        textTransform: "capitalize",
                        "&.Mui-disabled": {
                            backgroundColor: "#93C0FE",
                            color: "white"
                        },
                    }}
                >
                    {UIText.login.authbutton}
                </Button>
                {error && <Typography sx={{ color: "red" }}>{error}</Typography>} {/* Display error message if error state is not null */}

                <Stack sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography>
                        {UIText.login.point6}
                    </Typography>
                    <a href="https://app.axya.co/auth/register" target="_blank" rel="noreferrer">
                        <Button variant="text" sx={{ textTransform: "capitalize", textDecoration: "underline" }}>{UIText.login.join}</Button>
                    </a>
                </Stack>
            </Stack>
        </>
    );
};

export default Login;