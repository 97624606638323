/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { constants } from '../utils/Constants';
import { debugLog } from '../utils/debug';

export const GetMessage = (thread_id, accessToken, callback) => {
    if (thread_id != undefined && accessToken) {
        var settings = {
            "url": `${constants.BASE_URL}/api/v1/email/messages/${thread_id}/`,
            "method": "GET",
            "timeout": 0,
            "headers": {
                "Authorization": `JWT ${accessToken}`
            },
        };

        $.ajax(settings)
            .done(function (response) {
                if (response.id) {
                    callback(null, response);
                } else {
                    callback(response.messages.message, null);
                }
            })
            .fail(function (error) {
                if (error.status == 404) {
                    callback(error.status, null);
                } else {
                    callback(error, null);
                }
            });
    } else {
        debugLog("GetMessage.jsx, Line No: 30,", "GetMessage API not call due undefined thread_id parameter");
    }
}