/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { constants } from '../utils/Constants';
import CryptoJS from "crypto-js";
import { debugLog } from '../utils/debug';

export const RefreshToken = (accessToken, refreshToken, callback) => {
    const [tokenExpTime, setTokenExpTime] = useState('');
    const [decodedToken, setDecodedToken] = useState(null);

    useEffect(() => {
        const decoded = jwtDecode(accessToken);
        setTokenExpTime(decoded.exp * 1000);
        setDecodedToken(decoded);
    }, []);

    // Refresh Token Functionality
    useEffect(() => {
        if (tokenExpTime) {
            const refreshTheToken = () => {

                // Check if the token is about to expire in the next 24 hours (86400000ms)
                if (decodedToken.exp * 1000 < Date.now() + 86400000) {
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    const requestData = JSON.stringify({
                        refresh: refreshToken,
                    });

                    const requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: requestData,
                        redirect: "follow"
                    };

                    fetch(`${constants.BASE_URL}/api/v1/token/refresh/`, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.access) {
                                let accessToken = result.access;
                                const encryptedToken = CryptoJS.AES.encrypt(accessToken, constants.secretKey).toString();
                                localStorage.setItem("axay_access", encryptedToken);

                                if (result.access) {
                                    callback(null, accessToken);
                                }
                            } else {
                                debugLog("RefreshToken.js, Line No: 52, Invalid JSON structure in the response");
                                callback(error);
                            }
                        })
                        .catch((error) => {
                            debugLog("RefreshToken.js, Line No: 57, Error refreshing token:", error);
                            callback(error);
                        });
                } else {
                    const notExpireToken = "accessToken not expired yet";
                    callback(notExpireToken);
                }
            };

            refreshTheToken();
        }
    }, [decodedToken]);

    return { tokenExpTime };
};
