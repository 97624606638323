/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Stack, Box, Typography, Checkbox, Divider } from "@mui/material";
import UIText from "../../utils/TextResources";
import Search from "../Search/Search";
import Loader from "../../Loader/Loader";
import useLoader from "../../utils/UseLoader";
import Suggestions from "../Suggestions/Suggestions";
import SelectedSuggestion from "../Suggestions/SelectedSuggestion";
import UserInfo from "../../utils/UsersInfo";
import { Token } from "../../utils/accessToken";
import { Refresh } from "../../utils/refreshToken";
import { RefreshToken } from "../../Auth/RefreshToken";
import { GetMessage } from "../../services/GetMessage";
import { SuggestionList } from "../../services/SuggestionList";
import { SyncMessage } from "../../services/SyncMessage";
import { SyncAttachments } from "../../services/SyncAttachments";
import { UnSyncMessage } from "../../services/UnSyncMessage";
import { useNavigate } from "react-router-dom";
import Logout from "../Logout/Logout";
import { debugLog } from "../../utils/debug";

const Header = () => {
    const loading = useLoader();
    const navigate = useNavigate();
    const apiCalled = useRef(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(loading);
    const [showSearch, setShowSearch] = useState(loading);
    const [suggestionData, setSuggestionData] = useState();
    const [selectedObjectId, setSelectedObjectId] = useState(null);
    const [selectedObjectType, setSelectedObjectType] = useState(null);
    const [showSelectedSuggestion, setShowSelectedSuggestion] = useState();
    const { senderName, senderEmail, ccEmails, toEmails, mailBody, DateTime, message_id, thread_id, title, attachments, attachmentNames } = UserInfo();
    const [apiSync, setApiSync] = useState(false);
    const [syncMsg, setSyncMsg] = useState(false);
    const [getMessagesData, setGetMessagesData] = useState();
    const accessToken = Token();
    const refreshToken = Refresh();

    if (accessToken) {
        // Check RefreshToken is expire or not if RefreshToken is expire then call API
        RefreshToken(accessToken, refreshToken, (error, data, notExpireToken) => {
            if (error) {
                if (error != "accessToken not expired yet") {
                    debugLog("Header.jsx, Line No: 46, RefreshToken Error Callback", error.message);
                    setError(error.message);
                } else {
                    debugLog("Header.jsx, Line No: 49, refreshToken error", error);
                }
            } else {
                debugLog("Header.jsx, Line No: 52, RefreshToken Success Callback", data);
            }
        });
    }

    useEffect(() => {
        if (accessToken && thread_id != undefined && senderName != undefined && senderEmail != undefined && ccEmails != undefined && toEmails != undefined && mailBody != undefined && DateTime != undefined && message_id != undefined && !apiCalled.current) {
            setIsLoading(true);

            GetMessage(thread_id, accessToken, async (error, data) => {
                if (error) {
                    if (error == 404) {
                        setApiSync(true);
                        setIsLoading(false);
                    } else {
                        setError(error.responseJSON.messages[0].message);
                        setIsLoading(false);
                    }
                } else {
                    setGetMessagesData(data);
                    debugLog("Header.jsx, Line No: 74, Message exists:", data);
                    const match = data.obj_id.match(/(PO|RFQ)/);
                    if (match) {
                        const objType = match[0];  // Get the matched value, either "PO" or "RFQ"
                        debugLog("Header.jsx, Line No: 79, Message type:", objType);
                        await handleSelectedObjectType(objType);
                    } else {
                        debugLog("Header.jsx, Line No: 79", "PO or RFQ not found");
                    }

                    const messageExists = data.messages.some(message => message.message_id == message_id);
                    debugLog("Header.jsx, Line No: 86, Message exists:", messageExists);
                    var objid = data.id;

                    // Message with the ID exists 
                    if (messageExists == true) { // This is for testing messageExists == true
                        setSyncMsg(true);
                        setShowSelectedSuggestion(true);
                        setSelectedObjectId(objid);
                    } else {
                        // Message with the ID does not exist
                        handleSelectedObjectId(objid);
                        setSyncMsg(false);
                    }
                    setApiSync(false);
                    setIsLoading(false);
                }
            });
            apiCalled.current = true;
        } else {
            debugLog("Header.jsx, Line No: 89,", "All parameters not here");
        }

    }, [accessToken, senderName, senderEmail, ccEmails, toEmails, mailBody, DateTime, message_id, thread_id, title, syncMsg]);

    const handleSyncMessage = () => {
        setIsLoading(true);
        if (syncMsg) {
            // Call UnSyncMessage if checkbox is unchecked
            UnSyncMessage(accessToken, thread_id, (error, data) => {
                if (error) {
                    // setError(error.responseJSON.messages[0].message);
                    setError(error.responseText);
                    debugLog("Header.jsx, Line No: 106, unSync error", error);

                    if (error.status === 404) {
                        apiCalled.current = false;
                        setSyncMsg(false);
                        setIsLoading(false);

                        setShowSearch(false);
                        setSelectedObjectId(null);
                        setShowSelectedSuggestion(false);
                    }
                    setIsLoading(false);
                } else {
                    debugLog("Header.jsx, Line No: 120, unSync success", data);
                    apiCalled.current = false;
                    setSyncMsg(false);
                    setIsLoading(false);

                    setShowSearch(false);
                    setSelectedObjectId(null);
                    setShowSelectedSuggestion(false);
                    localStorage.removeItem('type');
                }
            });
        } else {
            // Show search and suggestion screens when user check the checkbox
            setShowSearch(true);

            SuggestionList(accessToken, senderName, senderEmail, ccEmails, toEmails, mailBody, DateTime, message_id, thread_id, title, attachmentNames, apiCalled, (error, data) => {
                if (error) {
                    debugLog("Header.jsx, Line No: 119, suggestionList error", error);
                    if (error.status == 400) {
                        setSyncMsg(false);
                        setShowSearch(false);
                        setIsLoading(false);

                        // Parse the JSON data
                        const data = JSON.parse(error.responseText);

                        // Check if "title" exists in the data, if not, check for "body"
                        if (data.title && data.title.length > 0) {
                            // Extract and print the title
                            const emptyTitle = "Title: " + data.title[0];
                            setError(emptyTitle);

                        } else if (data.messages && data.messages.length > 0) {
                            const message = data.messages[0]; // Assuming there's only one message

                            // Check if the message has a body
                            if (message.body && message.body.length > 0) {
                                // Extract and print the body
                                const emptyBody = "Body: " + message.body[0];
                                setError(emptyBody);
                            } else {
                                debugLog("Header.jsx, Line No: 143,", "Message has no body.");
                            }
                        } else {
                            debugLog("Header.jsx, Line No: 146,", "No title or message found.");
                        }
                    } else if (error.status == 500) {
                        setSyncMsg(true);
                        setError("Server Error");
                        setIsLoading(false);
                    } else {
                        setSyncMsg(true);
                        setError(error.responseJSON.messages[0].message);
                        setIsLoading(false);
                    }
                } else {
                    debugLog("Header.jsx, Line No: 158, Suggestion Callback", data);
                    setSuggestionData(data);
                    setSyncMsg(true);
                    setIsLoading(false);
                }
            });
        }
    };

    const handleSelectedObjectId = (objId) => {
        setIsLoading(true);
        setSelectedObjectId(objId);
        setShowSelectedSuggestion(true);

        // Call SyncMessage API
        SyncMessage(accessToken, objId, senderName, senderEmail, ccEmails, toEmails, mailBody, DateTime, message_id, thread_id, title, (error, data) => {
            if (error) {
                debugLog("Header.jsx, Line No: 175, syncMessage error", error);
                if (error.status == 404) {
                    setError("The requested resource was not found on this server");
                    setSelectedObjectId(null);
                    setIsLoading(false);
                } else {
                    setError(error.responseJSON.messages[0].message);
                    setSelectedObjectId(null);
                    setIsLoading(false);
                }
            } else {
                debugLog("Header.jsx, Line No: 188, syncSuccess", data);
                setError('');
                apiCalled.current = false;
                if (syncMsg == true) {
                    setSyncMsg(false);
                } else {
                    setSyncMsg(true);
                }
                setIsLoading(false);

                // Sync all attachments one by one if msg synced
                if (attachments) {
                    attachments.forEach((attachment, index) => {
                        SyncAttachments(accessToken, attachment, message_id, (error, data) => {
                            if (error) {
                                debugLog(`Header.jsx, Line No: 218, syncAttachment Error Callback`, error);
                                setError(error.responseJSON.messages[0].message);
                                setIsLoading(false);
                            } else {
                                debugLog(`Header.jsx, Line No: 222, syncAttachment Success Callback`, data);

                                // If it's the last attachment, stop the loading state
                                if (index === attachments.length - 1) {
                                    setIsLoading(false);
                                }
                            }
                        });
                    });
                }
            }
        });
    };

    const handleSelectedObjectType = async (objType) => {
        debugLog(`Header.jsx, Line No: 237, selected item type`, objType);
        setSelectedObjectType(objType);

        // Store in localStorage
        localStorage.setItem('type', objType);
    };

    // Retrieve selectedObjectType from localStorage when the component mounts
    useEffect(() => {
        const storedObjectType = localStorage.getItem('type');
        if (storedObjectType) {
            setSelectedObjectType(storedObjectType);
        }
    }, []);

    useEffect(() => {
        if (accessToken) {
            navigate("/header");
        } else {
            navigate("/");
        }
    }, []);

    return (
        <>
            {isLoading && (
                <Loader
                    loaderOverlay={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                    loader={{ size: "40px" }}
                />
            )}

            <Stack
                sx={{
                    minHeight: "100vh",  // Ensure the stack takes up the full viewport height
                    justifyContent: "space-between",  // Distribute space between children
                }}
            >
                {/* Content */}
                <Box>
                    <Divider sx={{ maxWidth: "96%" }} />
                    <Stack
                        padding="8px"
                        flexDirection="column"
                        textAlign="left"
                        marginTop="8px"
                        spacing={2}
                    >
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                            <Checkbox
                                color="primary"
                                checked={syncMsg}
                                onChange={handleSyncMessage}
                                sx={{
                                    marginTop: "-10px",
                                    marginLeft: "-10px"
                                }}
                            />
                            <Typography variant="body2" paddingTop="2px">
                                {UIText.header.trackOnAxya}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Divider sx={{ maxWidth: "96%" }} />

                    {showSearch && !selectedObjectId && (
                        <>
                            <Search handleSelectedObjectId={handleSelectedObjectId} handleSelectedObjectType={handleSelectedObjectType} />
                            <Suggestions suggestionData={suggestionData} handleSelectedObjectId={handleSelectedObjectId} />
                        </>
                    )}

                    {syncMsg && !error && selectedObjectId && selectedObjectType && showSelectedSuggestion && (
                        <SelectedSuggestion Getmessages={getMessagesData} selectedObjectType={selectedObjectType} />
                    )}

                    {/* Display error message if error state is not null */}
                    {error && <Typography sx={{ padding: "8px", fontSize: "12px", color: "red" }}>{error}</Typography>}
                </Box>

                {/* Show logout screen */}
                <Stack sx={{ width: "100%" }}>
                    <Divider sx={{ maxWidth: "94%", marginLeft: "6px" }} />
                    <Logout />
                    <Divider sx={{ maxWidth: "94%", marginLeft: "6px", paddingTop: "8px" }} />
                </Stack>
            </Stack>
        </>
    );
};

export default Header;
