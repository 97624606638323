/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import { constants } from '../utils/Constants';
import { debugLog } from '../utils/debug';

export const SyncMessage = (accessToken, objId, senderName, senderEmail, ccEmails, toEmails, mailBody, DateTime, message_id, thread_id, title, callback) => {
    if (accessToken && objId && senderName != undefined && senderEmail != undefined && ccEmails != undefined && toEmails != undefined && mailBody != undefined && DateTime != undefined && message_id != undefined && thread_id != undefined && title != undefined) {
        var settings = {
            "url": `${constants.BASE_URL}/api/v1/email/sync/${thread_id}/`,
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Authorization": `JWT ${accessToken}`,
                "Content-Type": "application/json"
            },
            "data": JSON.stringify({
                "obj_id": objId,
                "emails": {
                    "title": title,
                    "thread_id": thread_id,
                    "messages": [
                        {
                            "message_id": message_id,
                            "from_email": senderEmail,
                            "from_name": senderEmail,
                            "to": toEmails,
                            "cc": ccEmails,
                            "body": mailBody,
                            "date": DateTime
                        }
                    ]
                }
            }),
        };

        $.ajax(settings)
            .done(function (response) {
                callback(null, response);
            })
            .fail(function (error) {
                callback(error, null);
            });
    } else {
        debugLog("SyncMessage.jsx, Line No: 45,", "SyncMessage API not call due undefined his body parameters");
    }
}

