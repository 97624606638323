/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import React, { useState, useCallback } from 'react';
import { Stack, Button, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import UserInfo from '../../utils/UsersInfo';
import { logoutUser } from '../../services/logout';
import { Token } from '../../utils/accessToken';
import UIText from '../../utils/TextResources';
import useLoader from '../../utils/UseLoader';
import Loader from '../../Loader/Loader';
import { debugLog } from '../../utils/debug';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const isLoading = useLoader();
    const accessToken = Token();
    const Navigate = useNavigate();
    const [loading, setloading] = useState(isLoading);
    const { userDisplayName, userEmailAddress } = UserInfo();

    const handleLogout = useCallback(() => {
        setloading(true);

        logoutUser(accessToken, (error, data) => {
            if (error) {
                debugLog("Logout.jsx, Line No: 27, Logout Error Callback", error);
                setloading(false);
            } else {
                debugLog("Logout.jsx, Line No: 30, Logout Success Callback", data);
                localStorage.removeItem("axay_access");
                localStorage.removeItem("axay_refresh");
                setloading(false);
                Navigate("/");
            }
        });
    }, [accessToken, Navigate]);

    return (
        <>
            {loading && (
                <Loader
                    loaderOverlay={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                    loader={{ size: "40px" }}
                />
            )}

            <Stack spacing={2} sx={{ paddingLeft: "4px", marginTop: "12px" }}>
                <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FiberManualRecordIcon style={{ color: "#21E4A6" }} />
                    <Typography variant="body1" sx={{ marginLeft: "10px", fontWeight: "600" }}>{UIText.logout.connected}</Typography>
                </Stack>
                <Stack>
                    <Typography sx={{ fontSize: "13px", paddingLeft: "6px" }}>{userDisplayName} &lt;{userEmailAddress}&gt;</Typography>
                </Stack>
                <Stack sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                    <Button
                        variant="text"
                        sx={{
                            textTransform: "none",
                            marginRight: "12px"
                        }}
                        onClick={handleLogout}
                    >
                        {UIText.logout.logoutText}
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}

export default Logout;
