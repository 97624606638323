/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { constants } from '../utils/Constants';
import { debugLog } from '../utils/debug';

export const logoutUser = (accessToken, callback) => {
    if (accessToken) {
        var settings = {
            "url": `${constants.BASE_URL}/api/v1/auth/logout/`,
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Accept": "application/json, text/plain, */*",
                "Accept-Language": "en",
                "Authorization": `JWT ${accessToken}`,
                "Content-Type": "text/plain"
            },
            "data": "{}",
        };

        $.ajax(settings)
            .done(function (response) {
                callback(null, response);
            })
            .fail(function (error) {
                callback(error, null);
            });
    } else {
        debugLog("Logout.jsx, Line No: 29,", "accesstoken not found");
    }
}